<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            outlined
            dense
            :clearable="true"
            label="Nome da categoria"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-checkbox
            v-model="checkbox"
            label="Definir como subcategoria de:"
            class="ma-0"
            :hide-details="true"
          ></v-checkbox>
          <v-tree-select
            selected-color="fmq_black"
            :autocomplete="true"
            :small-chips="true"
            :dense="true"
            :items="categorias"
            :clearable="true"
            outlined
            :disabled="!checkbox"
            :allow-select-parents="true"
            item-children="subcategorias"
            item-text="nome"
            item-value="id"
            item-key="id"
            placeholder="Selecione a categoria mãe"
            v-model="formData.categoriasId"
            :value="formData.categoriasId"
          >
          </v-tree-select>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar Categoria" : "Criar Categoria" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "CategoriaFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    categoria: {
      type: Object,
      default: () => {},
    },
    categorias: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      nome: null,
      categoriasId: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
    checkbox: false,
  }),
  created() {
    if (this.$route.name === "CategoriasEditar") {
      this.formData.nome = this.categoria.nome;
      this.formData.categoriasId = this.categorias.filter(
        (categoria) => categoria.id === this.categoria.categoriasId
      );
      if (this.formData.categoriasId) {
        this.checkbox = true;
      }
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
