<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/categorias"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <CategoriaFormulario :categorias="categorias" @send="criar" />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Categoria criada com sucesso"
      @close="goToCategorias"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import CategoriaFormulario from "@/components/categorias/CategoriaFormulario.vue";
import { criarCategoria, todasCategorias } from "@/services/categorias";
export default {
  name: "CategoriasCriar",
  components: { CategoriaFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Categorias",
        disabled: false,
        to: "/categorias",
      },
      {
        text: "Criar Categoria",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    categorias: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getCategorias();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarCategoria({
          nome: event.nome,
          categoriasId: event.categoriasId ? event.categoriasId[0].id : null,
        }).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getCategorias() {
      this.categorias = [];
      try {
        this.loading = true;
        const resp = await todasCategorias();
        this.categorias = resp.data;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    goToCategorias() {
      this.sucess = false;
      this.$router.push({ name: "Categorias" });
    },
  },
};
</script>

<style></style>
